import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";

const imageURL = require('../../assets/images/logo.png');
const imageURL3x = require('../../assets/images/logo@3x.png');
const imageURL2x = require('../../assets/images/logo@2x.png');

const config = require("../../assets/config.json");


const Logo = (props) => {

    const logoRef = useRef();
    
    const handleLogoClass = ()=>{
        const logoWidth = logoRef.current.naturalWidth
        const logoHolder = document.getElementsByClassName('logoHolder')[0]
        if(logoWidth >300) {
            logoHolder.classList.add('flex-auto')
        }
    }
    return (
    <div  onClick={() => { 
        if(!(config.embeddedConfig &&config.embeddedConfig["content-mode"] &&config.embeddedConfig["content-mode"].toLowerCase() === "tenant"))
            props.history.push({ pathname: "/" }); 
        else
            props.history.push({ pathname: "/connect/clublist" }); 
    }} 
        className="logoHolder">
        {
            !(config.embeddedConfig && config.embeddedConfig.showLogo === false)?
            <React.Fragment>
                <div style={{
                }} >
                    <picture>
                        <source media="(min-width: 1050px)" srcSet={imageURL3x} width='100%' alt="Logo" />
                        <source media="(min-width: 465px)" srcSet={imageURL2x} width='100%' alt="Logo" />
                        <img ref={logoRef} onLoad={handleLogoClass} className="logoImg" src={imageURL}  alt="Logo" />
                    </picture>
                </div>
            </React.Fragment>:<React.Fragment>
            <Grid
              item
              className="makeTextClickable"
            >
              <Typography variant="h5" className="align-left" style={{color:config.primaryColor}}>
                {Translate({ id: "footer.home" })}
              </Typography>
            </Grid>
            </React.Fragment>
        }


    </div>
);}

export default withRouter(Logo);