import { initializeApp } from "firebase/app";
import { getPerformance,trace } from "firebase/performance";
class FirebaseApp {
  App;
  perfrence;
  traceData;
  constructor(){
    const firebaseConfig = process.env.REACT_APP_PLATFORM !== 'PROD' ? { apiKey: "AIzaSyCQkWtqt5GWzOEgCczQlfsY3YTQXmy9cBM", authDomain: "wexer-test.firebaseapp.com", databaseURL: "https://wexer-test.firebaseio.com", projectId: "wexer-test", storageBucket: "wexer-test.appspot.com", messagingSenderId: "448322517605", appId: "1:448322517605:web:6f7894e7786e50a258e48c", measurementId: "G-8BEXJYNZ0V" } : { apiKey: "AIzaSyCutyK6nxfNoUUmqO7dFijGGMpu9WdtCv8", authDomain: "wexer-live.firebaseapp.com", databaseURL: "https://wexer-live.firebaseio.com", projectId: "wexer-live", storageBucket: "wexer-live.appspot.com", messagingSenderId: "767642639501", appId: "1:767642639501:web:5774d88ee13c6d2cbd56c4", measurementId: "G-HBBF5YJJYE" }
    
    this.App = initializeApp(firebaseConfig);
  }
  defaultTrace(){
    try{
      this.perfrence = getPerformance();
      this.traceData = trace(this.perfrence);
    }
    catch(e){

    }
  }
  setUp(pageName = "home"){
    this.perfrence = getPerformance();
    this.traceData = trace(this.perfrence,pageName);
  }
  getDetails(){
    return {perfrence:this.perfrence,traceData:this.traceData};
  }
  setAttribute({key,value}){
    this.traceData.putAttribute(key,value);
  }
  getAttribute(key){
    this.traceData.gutAttribute(key);
  }
  getAttributes(){
    this.traceData.getAttributes();
  }
  delAttribute(key){
    this.traceData.removeAttribute(key);
  }
}


export {FirebaseApp};