import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
// import { useInternetSpeedMeter } from './InternetSpeedMeter';
const VideoPlayer = props => {
    const { startVideo,hideControls,file,autostart,initPlayer,image,eventId } = props;
    const JP = window.jwplayer;
    // const {getVideoDetails} =  useInternetSpeedMeter({imageUrl:image,eventId});
    let player=null;
    const playerInit =  useCallback(() => {
        try {
            if(JP && player === null){
                // eslint-disable-next-line react-hooks/exhaustive-deps
                player = JP(eventId).setup({
                    file,
                    image,
                    autostart,
                    events:{
                        'seeked':()=>{
                            console.log("SEEKED");
                        },
                        'ready':()=>{
                            handelRightClick(player);
                            hideControls && handelMenuBtns(player);
                        },
                        "beforePlay":()=>{
                            handelRightClick(player);
                        },
                        'play':()=>{
                            hideControls && handelMenuBtns(player)
                            if (player.getState() === 'PLAYING') {
                                player.hideControls();
                            }
                            // getVideoDetails(player)
                        },
                        'displayClick':function(){
                            // if(hideControls){
                            //     setTimeout(()=>{
                            //     this.play()
                            //     },150)
                            // }
                        },
                        "pause":()=>{
                                // player.setControls(false)
                            },
                        "fullscreen":()=>{
                            if(player.getFullscreen()){
                                console.log("in Fullscreen")
                            }
                            else{
                                console.log("not in Fullscreen")   
                            }
                        }
                    }
                });
                return player;
            }
            else{
                return null;
            }
        } catch (error) {
            console.log(error.message);
            console.log(error.name);
            return null; 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JP,initPlayer]);
    const handelRightClick = (Player)=>{
        const videoEle = Player.getContainer();
        videoEle.addEventListener("contextmenu",()=>{
            const menuEle = videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset")[0];
            menuEle.style.display="none";
            return false;
        });
        // videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset>li:first-child")[0].style.display="none";
    }
    const handelMenuBtns = (Player)=>{
        try {
            const videoEle = Player.getContainer();
            const rewind =  videoEle.querySelectorAll(".jw-icon-rewind");
            const slider =  videoEle.querySelectorAll(".jw-slider-horizontal");
            const duration =  videoEle.querySelectorAll(".jw-text-duration");
            const liveBtn =  videoEle.querySelectorAll(".jw-text-live");
            if(videoEle && rewind[1]){
                // rewind[1].style.display="none";
                console.log("CALLED");
            }
            if(videoEle && slider[0]){
                slider[0].style.pointerEvents="none";
            }
            if(videoEle && duration[0]){
                duration[0].style.display="none";
            }
            if(videoEle && liveBtn[0]){
                liveBtn[0].style.display="flex";
            }
        } catch (error) {
            console.log(error.name);
            console.log(error.message);
        }
    }
    const setUpVid = (video)=>{
        const Jplayer = playerInit();
        if(Jplayer)
        Jplayer.on({
            'ready':()=>{
                startVideo(video,Jplayer)
            },
            
        });
    }
    return (
        <div ref={(video=>setUpVid(video))} id={props.eventId}>
            <img alt="video" style={{width:'100%'}} src={props.image} />
        </div>
    )
}
VideoPlayer.defaultProps={
    isLive:false,
    autostart:false,
    hideControls:false,
    startVideo:()=>{},
    file:""
}
VideoPlayer.propTypes = {
    file : PropTypes.string,
    startVideo : PropTypes.func,
    autostart : PropTypes.bool,
    isLive : PropTypes.bool,
    hideControls : PropTypes.bool,
}

export {VideoPlayer}