import React, { useEffect, useState } from 'react';
import { Grid, Typography } from "@material-ui/core";
import Workout from '../../../hoc/WorkoutContainer';
import { Translate } from '../../../utils/Translate'
import { isIPAD } from '../../../utils/constants';
import moment from "moment";

const ClassOfTheDay = React.memo(function Cotd(props) {
    const { classOfTheDay } = props;
    const [temData, setTempData] = useState([])
    const [isIpad] = useState(isIPAD)


    useEffect(() => {
        if (props.classOfTheDay.collectionItems.length > 1) {
            let arr = []
            for (let index = 1; index < props.classOfTheDay.collectionItems.length; index++) {
                arr.push(props.classOfTheDay.collectionItems[index])
            }
            setTempData(arr)
        }
    }, [props.classOfTheDay.collectionItems])

    const checkFavStatus = (data) => {
        let classes =data;
        classes.favourite = !classes.favourite
    }
    const cotdDate = ()=>{
       try{
       return moment(classOfTheDay.collectionItems[0].displayDate).format("dddd DD MMMM") 
       } catch(e){
       return moment().format("dddd DD MMMM")
       }
    }
    return ( 
        <React.Fragment>        
                <Grid container direction="row" className="m-t-15 m-t-xs-32 m-b-xs-10">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h1" className="align-left clear">
                            {/* {classOfTheDay.collectionName} */}
                             {Translate({ id: "homePage.ClassOfTheDayTitle" })}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h6" className="align-left cardDescription-p text-gray hidden-xs">
                            {Translate({ id: "homePage.ClassOfTheDayDescription" })}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="align-left m-t-8 hidden-xs hidden-md">
                        <Typography variant="caption">
                            {cotdDate()}
                        </Typography>
                    </Grid>
                </Grid>
            
            <div className='codBg m-t-8'>
                    <Grid container justifyContent="flex-start" className='resposibleGrid cotd'>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Workout
                                keywords={classOfTheDay.collectionItems[0].keywords}
                                isSearchPageCard={false}
                                creationDate={classOfTheDay.collectionItems[0].scheduleDate}
                                trailerLinkWeb={classOfTheDay.collectionItems[0].trailerLinkWeb}
                                trailerLinkMobile={classOfTheDay.collectionItems[0].trailerLinkMobile}
                                isSliderWorkout={true}
                                url={classOfTheDay.collectionItems[0].streamingLink}
                                thumbnail={`${classOfTheDay.collectionItems[0].imageLink}`}
                                equipmentTypes={classOfTheDay.collectionItems[0].equipmentTypes}
                                equipmentNames={classOfTheDay.collectionItems[0].equipmentNames}
                                isFav={classOfTheDay.collectionItems[0].favourite}
                                title={classOfTheDay.collectionItems[0].className}
                                duration={classOfTheDay.collectionItems[0].durationSecond
                                    ? Math.floor(classOfTheDay.collectionItems[0].durationSecond / 60)
                                    : 55}
                                description={classOfTheDay.collectionItems[0].classDescription}
                                show={true}
                                calories={classOfTheDay.collectionItems[0].calorieBurn}
                                id={classOfTheDay.collectionItems[0].tag}
                                classCategory={classOfTheDay.collectionItems[0].classCategory}
                                clicked={()=>props.takeUserToClassDetail(0)}
                                level={classOfTheDay.collectionItems[0].level}
                                favCallback={() => { checkFavStatus(classOfTheDay.collectionItems[0]) }}
                            />
                        </Grid>
                        {isIpad === false && <Grid item xs={12} sm={12} md={12} lg={6} className="loginButtonHolder cotd2 hidden-md">
                            <Grid container spacing={2}>
                                {temData.map((data,index) =>
                                    <Grid item xs={12} sm={6} md={3} lg={6} key={Math.random()} >
                                        <Workout
                                            trailerLinkWeb={data.trailerLinkWeb}
                                            trailerLinkMobile={data.trailerLinkMobile}
                                            url={data.streamingLink}
                                            thumbnail={`${data.imageLink}`}
                                            title={data.className}
                                            show={true}
                                            calories={data.calorieBurn}
                                            id={data.tag}
                                            classCategory={data.classCategory}
                                            clicked={()=>props.takeUserToClassDetail(index+1)}
                                            favDisabled
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>}

                    </Grid>
                
            </div>
        </React.Fragment>
    );
});



export default ClassOfTheDay;

